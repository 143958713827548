import LineSale from "../assets/images/LineSaleMockUp.png";
import Parvo from "../assets/images/mock_parvo.png";
import Pavathi from "../assets/images/PFSproductPavathi_3.png"
import React, {Component} from "react";
import Heading from "../components/Heading";

class OurProduct extends Component {

    constructor(props) {
        super(props);
        this.state = {
            property: {
                width: "50px",
                transition: "width 0.5s"
            },
            propertyPavathi: {
                width: "50px",
                transition: "width 0.5s"
            },
            propertySoon: {
                width: "50px",
                transition: "width 0.5s"
            },
        };
        this.handleMouseOverKnowMore = this.handleMouseOverKnowMore.bind(this);
        this.handleMouseOutKnowMore = this.handleMouseOutKnowMore.bind(this);
        this.handleMouseOverPavathi = this.handleMouseOverPavathi.bind(this);
        this.handleMouseOutPavathi = this.handleMouseOutPavathi.bind(this);
        this.handleMouseOverSoon = this.handleMouseOverSoon.bind(this);
        this.handleMouseOutSoon = this.handleMouseOutSoon.bind(this);
        this.goToLineSale = this.goToLineSale.bind(this);
        this.goToPavathi = this.goToPavathi.bind(this);
    }

    handleMouseOverKnowMore() {
        this.setState({
            property: {
                width: "200px",
                transition: "width 0.5s"
            }
        });
    }

    handleMouseOutKnowMore() {
        this.setState({
            property: {
                width: "50px",
                transition: "width 0.5s"
            }
        });
    }

    handleMouseOverPavathi() {
        this.setState({
            propertyPavathi: {
                width: "200px",
                transition: "width 0.5s"
            }
        });
    }

    handleMouseOutPavathi() {
        this.setState({
            propertyPavathi: {
                width: "50px",
                transition: "width 0.5s"
            }
        });
    }

    handleMouseOverSoon() {
        this.setState({
            propertySoon: {
                width: "200px",
                transition: "width 0.5s"
            }
        });
    }

    handleMouseOutSoon() {
        this.setState({
            propertySoon: {
                width: "50px",
                transition: "width 0.5s"
            }
        });
    }

    goToLineSale = () => window.open('http://linesale.in', '_blank');

    goToPavathi = () => window.open('http://pavathi.in', '_blank');

    render() {
        return (
            <section id="ourProduct">
                <div className="container">
                    <div className="row line-sale" style={{marginBottom: '50px'}}>
                        <div id={"our-product"}><Heading heading={"Our Products"}/><br/></div>
                        <div style={{marginBottom: "30px"}}/>
                        <h1 data-aos="fade" data-aos-duration={600}
                            className={"mobile-line-sale"}
                            style={{
                                color: 'rgb(253, 1, 103)',
                                fontSize: '40px',
                                fontWeight: "700",
                                marginBottom: "25px"
                            }}>LINE SALE
                        </h1>
                        <div className="col-md-5 d-xl-flex justify-content-xl-end col-lg-5">
                            <img className="img-fluid"
                                 data-aos="fade-down"
                                 data-aos-duration={600}
                                 src={LineSale} alt={""}
                                 width={"350px"}/>
                        </div>
                        <div className="col-md-6 col-lg-7 d-flex flex-column justify-content-start">
                            <div className="row">
                                <div className="col">
                                    <h1 data-aos="fade" data-aos-duration={600}
                                        className={"normal-line-sale"}
                                        style={{
                                            color: 'rgb(253, 1, 103)',
                                            fontSize: '40px',
                                            fontWeight: "700",
                                            marginBottom: "25px"
                                        }}>LINE SALE
                                    </h1>
                                    <h6 data-aos="fade" data-aos-duration={600}
                                        style={{
                                            fontSize: '18px',
                                            fontFamily: 'Maitree, serif',
                                            fontWeight: "300",
                                            lineHeight: "1.7",
                                            marginBottom: "25px",
                                            color: "#000"
                                        }}>
                                        Line Sale Application is a perfect bookkeeping real-time application.
                                        Our app ensures a systematic &amp; transparent way to do business.
                                        The accounts are made very easy
                                        use &amp; manage. Provides an organized &amp; hassle-free way of carrying out
                                        business operations digitally.
                                    </h6>
                                </div>
                            </div>
                            <h6 data-aos="fade" data-aos-duration={600}
                                style={{
                                    fontFamily: 'Maitree, serif',
                                    color: "#9EA1AC",
                                    marginBottom: "auto",
                                    fontWeight: "300",
                                    fontSize: "18px"
                                }}>
                                Book-Keeping |&nbsp;Real-time Tracking |&nbsp;Reports<br/>
                            </h6>
                            <div className="row">
                                <div className="col">
                                    <h2 data-aos="fade"
                                        data-aos-duration={600}
                                        onMouseOver={this.handleMouseOverKnowMore}
                                        onMouseOut={this.handleMouseOutKnowMore}
                                        onClick={this.goToLineSale}
                                        style={{
                                            color: 'rgb(253, 1, 103)',
                                            fontSize: '24px',
                                            fontFamily: '"Gilroy Sans",sans-serif',
                                            fontWeight: "700",
                                            marginTop: "50px",
                                            cursor: "pointer"
                                        }}>
                                        Know more<br/>
                                    </h2>
                                    <div style={{
                                        width: this.state.property.width,
                                        transition: this.state.property.transition,
                                        height: '2vmin',
                                        background: 'var(--bs-yellow)',
                                        borderRadius: '25px'
                                    }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"container-fluid"} style={{marginTop: '100px'}}>
                    <div className="row d-xl-flex justify-content-xl-end" style={{width: "1110"}}>
                        <div style={{marginTop: "20px"}}/>
                        <div className="col-md-7 d-flex flex-column justify-content-start col-lg-6 pavathi-container">
                            <div className="row">
                                <div className="col">
                                    <h2 data-aos="fade-down" data-aos-duration={600}
                                        style={{
                                            color: '#2b3a91',
                                            fontSize: '40px',
                                            fontWeight: "400",
                                            marginBottom: "25px"
                                        }}>
                                        <strong>PAVATHI</strong>
                                        <br/>
                                    </h2>
                                </div>
                            </div>
                            <div className="row">
                                <img data-aos="fade-down"
                                     data-aos-duration={600}
                                     className="img-fluid mobile-line-sale" src={Pavathi} alt={""} width={"350px"}/>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="row">
                                        <div className="col">
                                            <h5 data-aos="fade" data-aos-duration={600}
                                                style={{
                                                    fontFamily: 'Maitree, serif',
                                                    fontSize: '18px',
                                                    fontWeight: "300",
                                                    lineHeight: "1.7"
                                                }}>
                                                The goal of Pavathi data management is to help people, organizations,
                                                and connect recharge related entities by optimizing the use of Recharge
                                                data within the bounds of policy and regulation, so that they can make
                                                decisions and take beneficial actions that maximize their profits,
                                                stop Leakages and malfunctions.<br/><br/>
                                                Pavathi tracking software is used for observing every Recharge
                                                move which is done with a third-party software as well as with the API.
                                                Software tracks every sequence of supply, sale, commissions,
                                                and incentive. Pavathi software is an eagle eye on data processing
                                                for multi-recharge systems. It’s a complete accounting and
                                                tracking system software available in India.<br/><br/>
                                                Hours of human work can be reduced as our software does all the
                                                tracking and accounting in minutes. Leakage can be identified
                                                with 100% accuracy, as it’s the only possible way of software tracking.
                                                Transfer recharge sale, incentive, leakage, commission,
                                                and every other function is automated.<br/><br/>
                                            </h5>
                                            <h2 style={{color: 'rgb(158, 161, 172)'}}>{" "}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 data-aos="fade"
                                data-aos-duration={600}
                                onMouseOver={this.handleMouseOverPavathi}
                                onMouseOut={this.handleMouseOutPavathi}
                                onClick={this.goToPavathi}
                                style={{
                                    color: '#2b3a91',
                                    cursor: "pointer",
                                    fontSize: "24px",
                                    fontFamily: '"Gilroy Sans",sans-serif',
                                    fontWeight: "700"
                                }}>
                                Know more<br/>
                            </h2>
                            <div
                                onMouseOver={this.handleMouseOverPavathi}
                                onMouseOut={this.handleMouseOutPavathi}
                                style={{
                                    width: this.state.propertyPavathi.width,
                                    transition: this.state.propertyPavathi.transition,
                                    height: '2vmin',
                                    background: 'var(--bs-yellow)',
                                    borderRadius: '25px'
                                }}>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img data-aos="fade-down"
                                 data-aos-duration={600}
                                 className="img-fluid normal-line-sale" src={Pavathi} alt={""} width={"350px"}/>
                        </div>
                        <div style={{marginTop: "20px"}}/>
                    </div>
                </div>

                <div className={"container-fluid"} style={{marginTop: '100px'}}>
                    <div className="row d-xl-flex justify-content-xl-end">
                        <div style={{marginTop: "20px"}}/>
                        <h2 data-aos="fade-down" data-aos-duration={600}
                            className={"mobile-line-sale"}
                            style={{
                                color: 'rgb(139, 0, 148)',
                                fontSize: '40px',
                                fontWeight: "400",
                                marginBottom: "25px"
                            }}>
                            <strong>PARVO</strong>
                            <br/>
                        </h2>
                        <div className="col-md-5 d-xl-flex justify-content-xl-end col-lg-5">
                            <img data-aos="fade-down"
                                 data-aos-duration={600}
                                 className="img-fluid" src={Parvo} alt={""} width={"370px"}/>
                        </div>
                        <div className="col-md-6 col-lg-7 d-flex flex-column justify-content-start">
                            <div className="row">
                                <div className="col">
                                    <h2 data-aos="fade-down" data-aos-duration={600}
                                        className={"normal-line-sale"}
                                        style={{
                                            color: 'rgb(139, 0, 148)',
                                            fontSize: '40px',
                                            fontWeight: "400",
                                            marginBottom: "25px"
                                        }}>
                                        <strong>PARVO</strong>
                                        <br/>
                                    </h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="row">
                                        <div className="col">
                                            <h5 data-aos="fade" data-aos-duration={600}
                                                style={{
                                                    fontFamily: 'Maitree, serif',
                                                    fontSize: '18px',
                                                    fontWeight: "300",
                                                    lineHeight: "1.7"
                                                }}>A unique and all in one app to connect our community.
                                                <br/>A platform that connects everyone, a platform
                                                that helps networking for our commune.<br/>
                                                A project that we are enthusiastically developing with all
                                                our hearts in it,<br/>Parvo, one of our
                                                biggest projects which is currently under development.<br/>
                                            </h5>
                                            <h2 style={{color: 'rgb(158, 161, 172)'}}>{" "}</h2>
                                        </div>
                                    </div>
                                    <h2>
                                        <span style={{
                                            fontSize: '18px', fontWeight: "300",
                                            fontFamily: 'Maitree, serif',
                                            color: "#9EA1AC", marginTop: "20px", marginBottom: "20px"
                                        }}>
                                            Unique |&nbsp;Connect |&nbsp;Network |&nbsp;Community<br/>
                                        </span>
                                        <br/>
                                    </h2>
                                </div>
                            </div>
                            <h2 data-aos="fade"
                                data-aos-duration={600}
                                onMouseOver={this.handleMouseOverSoon}
                                onMouseOut={this.handleMouseOutSoon}
                                style={{
                                    color: 'rgb(139, 0, 148)',
                                    cursor: "pointer",
                                    fontSize: "24px",
                                    fontFamily: '"Gilroy Sans",sans-serif',
                                    fontWeight: "700"
                                }}>
                                Coming Soon<br/>
                            </h2>
                            <div
                                onMouseOver={this.handleMouseOverSoon}
                                onMouseOut={this.handleMouseOutSoon}
                                style={{
                                    width: this.state.propertySoon.width,
                                    transition: this.state.propertySoon.transition,
                                    height: '2vmin',
                                    background: 'var(--bs-yellow)',
                                    borderRadius: '25px'
                                }}>
                            </div>
                        </div>
                        <div style={{marginTop: "20px"}}/>
                    </div>
                </div>
            </section>
        );
    }
}

export default OurProduct;