import React, { Component } from "react";
import androidInActive from "../assets/icon/android_inactive.png";
import androidActive from "../assets/icon/android_active.png";
import appleInActive from "../assets/icon/apple_inactive.png";
import appleActive from "../assets/icon/apple_active.png";
import rightImage from "../assets/images/videoplayer_wrap.png";
import homeVideo from "../assets/videos/PFS-Edited-Video-WEBMCOMPRESSED.webm";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: androidInActive,
      appleSrc: appleInActive,
    };
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.handleMouseOverApple = this.handleMouseOverApple.bind(this);
    this.handleMouseOutApple = this.handleMouseOutApple.bind(this);
  }

  handleMouseOver() {
    this.setState({ imgSrc: androidActive });
  }

  handleMouseOut() {
    this.setState({ imgSrc: androidInActive });
  }

  handleMouseOverApple() {
    this.setState({ appleSrc: appleActive });
  }

  handleMouseOutApple() {
    this.setState({ appleSrc: appleInActive });
  }

  render() {
    return (
      <section id="home">
        <div
          className="container"
          style={{ marginTop: "50px", marginBottom: "95px" }}
        >
          <div className="row d-lg-flex align-items-center align-items-lg-center">
            <div className="col-md-6 justify-content-center">
              <h4
                style={{
                  color: "rgb(0, 148, 68)",
                  fontFamily: '"Gilroy Sans",sans-serif',
                  fontWeight: "bold",
                  fontSize: "24px",
                }}
              >
                Welcome to Paper Free Solutions
              </h4>
              <h1>
                <strong>Mobile Application</strong>
                <br />
              </h1>
              <h1>
                <strong>Development Company</strong>
                <br />
              </h1>
              <h6
                style={{
                  color: "#000",
                  fontFamily: '"Maitree",sans-serif',
                  fontSize: "18px",
                  fontWeight: "300",
                  lineHeight: "30px",
                  marginBottom: "30px",
                }}
              >
                Our aim is to create user-friendly technologies for the common
                people by reducing the use of paper. Let's give a better nature
                to the future generation.
                <br />
              </h6>
              <div className="row justify-content-between align-items-center">
                <div className="col text-start">
                  <img
                    className="img-fluid"
                    id="apple-logo"
                    style={{ margin: "8px 20px 8px 0px" }}
                    width={45}
                    height={45}
                    alt="ios"
                    src={this.state.appleSrc}
                    onMouseOver={this.handleMouseOverApple}
                    onMouseOut={this.handleMouseOutApple}
                  />
                  <img
                    className="img-fluid"
                    id="android-logo"
                    style={{ margin: "8px 20px 8px 8px" }}
                    width={45}
                    height={45}
                    alt="android"
                    onMouseOver={this.handleMouseOver}
                    onMouseOut={this.handleMouseOut}
                    src={this.state.imgSrc}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col text-center d-xl-flex justify-content-xl-end video-wrapper">
                  <img
                    className="img-fluid d-xl-flex video-frame"
                    src={rightImage}
                    alt={""}
                  />

                  <video className="homeVideo" autoPlay loop muted>
                    <source src={homeVideo} />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Home;
