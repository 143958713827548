import EA from "../assets/images/1_EA.png";
import CA from "../assets/images/2_CA.png";
import R from "../assets/images/3_R.png";
import ID from "../assets/images/4_ID.png";
import T from "../assets/images/5_T.png";
import MAD from "../assets/images/6_MAD.png";
import React, {Component} from "react";
import Heading from "../components/Heading";

class WhyUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: "145px",
            background: "#FFD42D"
        };
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
        this.goToLineSale = this.goToLineSale.bind(this);
        this.goToPavathi = this.goToPavathi.bind(this);
    }

    handleMouseOver = () => this.setState({width: "170px", background: "#31C844"});

    handleMouseOut = () => this.setState({width: "145px", background: "#FFD42D"});

    goToLineSale = () => window.open('http://linesale.in', '_blank');

    goToPavathi = () => window.open('http://pavathi.in', '_blank');

    render() {
        return (
            <section id="whyUs">
                <div
                    data-bs-ride="carousel"
                    data-aos="fade-down"
                    data-aos-duration={500}
                    className="carousel slide"
                    id="carousel-1"
                    style={{background: '#009444', padding: '16px'}}>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <h1 className="text-center" id={"carousel-item"}>
                                <div className="row d-xl-flex justify-content-xl-center align-items-xl-center">
                                    <div className="col d-xl-flex justify-content-xl-center">
                                        <h1 style={{color: 'var(--bs-white)'}}>Line Sale - Real-time Book-keeping
                                            Application<br/>
                                        </h1>
                                    </div>
                                </div>
                                <div className="row" style={{paddingTop: "30px"}}>
                                    <div className="col d-xl-flex justify-content-xl-center">
                                        <button className="btn btn-primary"
                                                type="button"
                                                onMouseOver={this.handleMouseOver}
                                                onMouseOut={this.handleMouseOut}
                                                onClick={this.goToLineSale}
                                                style={{
                                                    color: '#000',
                                                    borderStyle: 'none',
                                                    background: this.state.background,
                                                    borderRadius: '16px',
                                                    width: this.state.width,
                                                    height: "50px",
                                                    transition: "width 0.5s",
                                                    fontWeight: '700',
                                                    fontFamily: 'Maitree, serif',
                                                    fontSize: "18px"
                                                }}>
                                            Know more
                                        </button>
                                    </div>
                                </div>
                            </h1>
                        </div>
                        <div className="carousel-item">
                            <h1 className="text-center" id={"carousel-item"}>
                                <div className="row d-xl-flex justify-content-xl-center align-items-xl-center">
                                    <div className="col d-xl-flex justify-content-xl-center">
                                        <h1 style={{color: 'var(--bs-white)'}}>Pavathi - Digitized Sales Tracking
                                            Application<br/>
                                        </h1>
                                    </div>
                                </div>
                                <div className="row" style={{paddingTop: "30px"}}>
                                    <div className="col d-xl-flex justify-content-xl-center">
                                        <button className="btn btn-primary"
                                                type="button"
                                                onMouseOver={this.handleMouseOver}
                                                onMouseOut={this.handleMouseOut}
                                                onClick={this.goToPavathi}
                                                style={{
                                                    color: '#000',
                                                    borderStyle: 'none',
                                                    background: this.state.background,
                                                    borderRadius: '16px',
                                                    width: this.state.width,
                                                    height: "50px",
                                                    transition: "width 0.5s",
                                                    fontWeight: '700',
                                                    fontFamily: 'Maitree, serif',
                                                    fontSize: "18px"
                                                }}>
                                            Know more
                                        </button>
                                    </div>
                                </div>
                            </h1>
                        </div>
                    </div>
                    <div>
                        <a
                            href={"#carousel-1"}
                            role="button"
                            data-bs-slide="prev"
                            className="carousel-control-prev"
                        >
                            <span aria-hidden="true" className="carousel-control-prev-icon"/>
                            <span className="visually-hidden">Previous</span>
                        </a>
                        <a
                            href={"#carousel-1"}
                            role="button"
                            data-bs-slide="next"
                            className="carousel-control-next"
                        >
                            <span aria-hidden="true" className="carousel-control-next-icon"/>
                            <span className="visually-hidden">Next</span>
                        </a>
                    </div>
                    <ol className="carousel-indicators">
                        <li data-bs-target="#carousel-1" data-bs-slide-to={0} className="active"/>
                        <li data-bs-target="#carousel-1" data-bs-slide-to={1}/>
                    </ol>
                </div>


                {/* Start: Article List */}
                <section className="article-list" style={{background: 'var(--bs-light)'}}>
                    <div className="container" style={{textAlign: 'left'}}>
                        <Heading heading={"Why us?"}/>
                    </div>
                    <div className="container">
                        {/* Start: Articles */}
                        <div className="row articles">
                            <div className="col-sm-6 col-md-4 item" data-aos="fade-up" data-aos-duration={600}><img
                                className="img-fluid d-xl-flex justify-content-xl-start" src={EA} width={110}
                                height={110} alt={""}/>
                                <h3 className="d-xl-flex justify-content-xl-start name"
                                    style={{fontSize: '18px', padding: '20px 0px', fontFamily: 'Maitree, serif'}}>
                                    <strong style={{fontWeight: "700"}}>Experience
                                        &amp; Accuracy</strong><br/></h3>
                                <p className="text-justify description"
                                   style={{
                                       fontFamily: 'Maitree, serif',
                                       fontSize: '18px',
                                       color: 'var(--bs-dark)',
                                       lineHeight: "30px",
                                       fontWeight: "300"
                                   }}>
                                    Our eCommerce Company
                                    <b>
                                        <a className={"other-link"}
                                           rel={"noreferrer"}
                                           target={"_blank"} style={{fontWeight: "700"}}
                                           href={"http://www.ezeecharge.com/"}> Ezeecharge </a>
                                    </b>
                                    is rated 4.8 out of 5, and having 11 years of experience in eCommerce
                                    Business, we have 8000+ satisfied retailers. We also run
                                    <b>
                                        <a className={"other-link"}
                                           rel={"noreferrer"}
                                           target={"_blank"} style={{fontWeight: "700"}}
                                           href={"http://www.royalinfotechindia.com/"}> Royal
                                            Infotech</a>
                                    </b> since 20+
                                    years.<br/>
                                </p>
                            </div>
                            <div className="col-sm-6 col-md-4 item" data-aos="fade-up" data-aos-duration={600}><img
                                className="img-fluid d-xl-flex justify-content-xl-start" src={CA} width={110}
                                height={110} alt={""}/>
                                <h3 className="d-xl-flex justify-content-xl-start name"
                                    style={{fontSize: '18px', padding: '20px 0px', fontFamily: 'Maitree, serif'}}>
                                    <strong style={{fontWeight: "700"}}>Cost-Effective &amp; Accurate</strong><br/></h3>
                                <p className="text-justify d-xl-flex description"
                                   style={{
                                       fontFamily: 'Maitree, serif',
                                       fontSize: '18px',
                                       color: 'var(--bs-dark)', lineHeight: "30px", fontWeight: "300"
                                   }}>People
                                    management
                                    has become very expensive, having a software and a person for data entry is very
                                    difficult,
                                    hence we have developed Line Sale, a Real-time cost-effective application.<br/></p>
                            </div>
                            <div className="col-sm-6 col-md-4 item" data-aos="fade-up" data-aos-duration={600}><img
                                className="img-fluid d-xl-flex justify-content-xl-start" src={R} width={110}
                                height={110} alt={""}/>
                                <h3 className="d-xl-flex justify-content-xl-start name"
                                    style={{
                                        fontSize: '18px',
                                        padding: '20px 0px',
                                        fontFamily: 'Maitree, serif',
                                        fontWeight: "700"
                                    }}>
                                    <strong style={{fontWeight: "700"}}>Responsive</strong><br/></h3>
                                <p className="text-justify d-xl-flex justify-content-xl-start description"
                                   style={{
                                       fontFamily: 'Maitree, serif',
                                       fontSize: '18px',
                                       color: 'var(--bs-dark)',
                                       lineHeight: "30px",
                                       fontWeight: "300"
                                   }}>In
                                    changing
                                    scenarios, understanding, and acting as per the need of the market is important.
                                    People
                                    management has become expensive. We have developed Line Sale-App, a Real-time
                                    application
                                    with minimum monthly rental system to make this easier.<br/></p>
                            </div>
                            <div className="col-sm-6 col-md-4 item" data-aos="fade-up" data-aos-duration={600}><img
                                className="img-fluid d-xl-flex justify-content-xl-start" src={ID} width={110}
                                height={110} alt={""}/>
                                <h3 className="d-xl-flex justify-content-xl-start name"
                                    style={{fontSize: '18px', padding: '20px 0px', fontFamily: 'Maitree, serif'}}>
                                    <strong style={{fontWeight: "700"}}>Interface
                                        Design</strong><br/></h3>
                                <p className="text-justify d-xl-flex justify-content-xl-start description"
                                   style={{
                                       fontFamily: 'Maitree, serif',
                                       fontSize: '18px',
                                       color: 'var(--bs-dark)', lineHeight: "30px", fontWeight: "300"
                                   }}>While
                                    creating
                                    the user interface design, we simplify, analyze and study real time errors in
                                    micro-levels.
                                    Before implementation and deployment, we make sure our product is user-friendly and
                                    error
                                    free with accuracy.<br/></p>
                            </div>
                            <div className="col-sm-6 col-md-4 item" data-aos="fade-up" data-aos-duration={600}><img
                                className="img-fluid d-xl-flex justify-content-xl-start" src={T} width={110}
                                height={110} alt={""}/>
                                <h3 className="d-xl-flex justify-content-xl-start name"
                                    style={{fontSize: '18px', padding: '20px 0px', fontFamily: 'Maitree, serif'}}>
                                    <strong style={{fontWeight: "700"}}>Testing</strong><br/></h3>
                                <p className="text-justify d-xl-flex justify-content-xl-start description"
                                   style={{
                                       fontFamily: 'Maitree, serif',
                                       fontSize: '18px',
                                       color: 'var(--bs-dark)', lineHeight: "30px", fontWeight: "300"
                                   }}>Initial
                                    testing
                                    is performed in-house, in the second stage it is tested by people working in
                                    relevant
                                    businesses. To understand the end-user experience better &amp; solve their
                                    difficulties
                                    in
                                    real-time we apply their market experience to our system interface.<br/></p>
                            </div>
                            <div className="col-sm-6 col-md-4 item" data-aos="fade-up" data-aos-duration={600}><img
                                className="img-fluid d-xl-flex justify-content-xl-start" src={MAD} width={110}
                                height={110} alt={""}/>
                                <h3 className="d-xl-flex justify-content-xl-start name"
                                    style={{fontSize: '18px', padding: '20px 0px', fontFamily: 'Maitree, serif'}}>
                                    <strong style={{fontWeight: "700"}}>Mobile
                                        App&nbsp; Development</strong><br/></h3>
                                <p className="text-justify d-xl-flex justify-content-xl-start description" style={{
                                    fontFamily: 'Maitree, serif',
                                    fontSize: '18px',
                                    color: 'var(--bs-dark)', lineHeight: "30px", fontWeight: "300"
                                }}>Providing the
                                    best solutions in the industry that makes your mobile app remarkable with elegant
                                    designs
                                    powered by the latest technologies. Our Moto is to engage, enable and deliver
                                    accuracy
                                    in
                                    the product to our clientele.<br/></p>
                            </div>
                        </div>
                        {/* End: Articles */}
                    </div>
                </section>
                {/* End: Article List */}
            </section>
        );
    }
}

export default WhyUs;
