import PD from "../assets/images/process_Development_2.png";
import PS from "../assets/images/process_supports.png";
import React from "react";
import Heading from "../components/Heading";

function Steps({stepNum, head, text, delay = 0}) {
    return (
        <div className="row" data-aos="fade" data-aos-duration={600} data-aos-delay={delay}>
            <div className="col">
                <div className="row">
                    <div className="col">
                        <h4 style={{
                            color: 'rgb(158, 161, 172)',
                            fontFamily: 'Maitree, serif',
                            fontSize: '18px',
                            fontWeight: "800"
                        }}>
                            {stepNum}
                        </h4>
                    </div>
                </div>
                <h2 style={{fontFamily: 'Ubuntu, sans-serif', fontSize: '28px'}}>
                    {head}<br/>
                </h2>
                <h5 style={{
                    fontSize: '18px',
                    fontFamily: 'Maitree, serif',
                    fontWeight: "300",
                    lineHeight: "30px"
                }}>{text}<br/>
                </h5>
            </div>
        </div>
    );
}

function OurProcessHead() {
    return (
        <div>
            <div className="container px-xl-5 px-md-12">
                <div className="row d-xl-flex justify-content-xl-center"
                     style={{marginBottom: '50px', marginTop: '30px'}}>
                    <div className="col col-lg-10" style={{marginRight: '50px'}}>
                        <Heading heading={"Our Process"}/><br/>
                        <h6 data-aos="fade" data-aos-duration={600}
                            style={{
                                fontFamily: 'Maitree, serif',
                                fontSize: '18px',
                                fontWeight: "300",
                                lineHeight: "30px"
                            }}>Our
                            process encourages us to pay attention to what people want and love. In Business, we love to
                            have peace guaranteed, our solutions and products can bring out the best outfit to all the
                            users, our products are very simplified, thus simplification creates love towards the
                            product,
                            using our products more and more brings absolute confidence in the user.<br/></h6>
                    </div>
                </div>
            </div>
        </div>
    );
}

function Development() {
    return (
        <div>
            <div className="row" style={{marginBottom: '50px'}}>
                <div className="col-md-6 d-xl-flex justify-content-xl-end col-lg-5">
                    <img className="img-fluid" src={PD} alt={""} width={"450px"}/>
                </div>
                <div className={"our-process-space"}/>
                <div className="col-md-6 d-flex flex-column justify-content-between">
                    <Steps stepNum={"01"} head={"User interface Design"}
                           text={"While designing the user interface, we keep our user in our mind, " +
                           "this process will help us to give life to the designs."}/>
                    <div className={"our-process-space"}/>
                    <Steps stepNum={"02"} head={"Database Development"} delay={200}
                           text={"Developing the core functionalities, database, servers etc. " +
                           "to make the project a market-ready product."}/>
                    <div className={"our-process-space"}/>
                    <Steps stepNum={"03"} head={"API"} delay={300}
                           text={"While designing the user interface, we keep our user in our mind, " +
                           "this process will help us to give life to the designs."}/>
                </div>
            </div>
        </div>
    );
}

function Support() {
    return (
        <div>
            <div className="row d-xl-flex justify-content-xl-end"
                 style={{marginTop: '100px', marginBottom: "100px"}} id={"support"}>
                <div className="col-md-6 d-flex flex-column justify-content-between">
                    <Steps stepNum={"01"} head={"Testing"}
                           text={"Initial product/apps testing is done at our development centre " +
                           "we validate every aspect of the product. Once the product testing is done, " +
                           "we give the product to real-time users to find out real-time bugs, " +
                           "User satisfaction is the best method to confirm the product is well tested"}/>
                    <div className={"our-process-space"}/>
                    <Steps stepNum={"02"} head={"Release & Implementation"} delay={200}
                           text={"Once the user satisfaction is confirmed, " +
                           "we release the product live in the app store."}/>
                    <div className={"our-process-space"}/>
                    <Steps stepNum={"03"} head={"Customer Support"} delay={300}
                           text={"Distinguishable users find difficulties in understanding the features, " +
                           "the customer is provided with live support and digital support. " +
                           "We serve our clients continuously for any feature additions or upgrades."}/>
                    <div className={"our-process-space"}/>
                </div>
                <div className="col-md-6 d-xl-flex justify-content-xl-end col-lg-5">
                    <img className="img-fluid" src={PS} alt={""} width={"450px"} style={{marginBottom: "100px"}}/>
                </div>
                <div style={{marginBottom: "30px"}}/>
            </div>
        </div>
    );
}

function OurProcess() {
    return (
        <section id="ourProcess" style={{background: '#F0F1F2'}}>
            <OurProcessHead/>
            <div className="container px-xl-5 px-md-12" style={{marginBottom: '50px', background: "#F0F1F2"}}>
                <Development/>
                <Support/>
            </div>
        </section>
    );
}

export default OurProcess;