import React, { Component } from "react";
import { FiPhone, FiMail, FiMapPin, FiClock, FiYoutube } from "react-icons/fi";

import Heading from "../components/Heading";

const ContactIcon = [
  {
    icon: <FiPhone className="icon" />,
    content: "+91 8073795822",
    link: "tel:+91 8073795822",
    newTab: true,
  },
  {
    icon: <FiMail className="icon" />,
    content: "support@paperfreesolutions.com",
    link: "mailto:support@paperfreesolutions.com",
    newTab: true,
  },
  {
    icon: <FiMapPin className="icon" />,
    content: "Udupi, Karnataka",
    link: "#home",
    newTab: false,
  },
  {
    icon: <FiClock className="icon" />,
    content: "Mon-Sat | 9 AM - 7PM",
    link: "#home",
    newTab: false,
  },
  {
    icon: <FiYoutube className="icon" />,
    content: "Check our videos on Youtube",
    link: "https://www.youtube.com/channel/UCTa5FRADL0CT2fmkhqGzIrg",
    newTab: true,
  },
];

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property: {
        width: "50px",
        transition: "width 0.5s",
      },
      btnProperty: {
        width: "150px",
        background: "#009444",
      },
      formDetail: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
    };
    this.handleMouseOverContact = this.handleMouseOverContact.bind(this);
    this.handleMouseOutContact = this.handleMouseOutContact.bind(this);

    this.handleMouseOverBtn = this.handleMouseOverBtn.bind(this);
    this.handleMouseOutBtn = this.handleMouseOutBtn.bind(this);
  }

  handleMouseOverContact() {
    this.setState({
      property: {
        width: "200px",
        transition: "width 0.5s",
      },
    });
  }

  handleMouseOutContact() {
    this.setState({
      property: {
        width: "50px",
        transition: "width 0.5s",
      },
    });
  }

  handleMouseOverBtn = () =>
    this.setState({ btnProperty: { width: "180px", background: "#31C844" } });

  handleMouseOutBtn = () =>
    this.setState({ btnProperty: { width: "150px", background: "#009444" } });

  render() {
    return (
      <section id="contact">
        <div className={"contact-f"} style={{ background: "#F0F1F2" }}>
          <footer className="footer-clean" style={{ background: "#F0F1F2" }}>
            <div className="container">
              <div style={{ marginBottom: "30px" }} id={"hidden-contact"}>
                <Heading heading={"Contact Us"} />
              </div>
              <div className="row justify-content-center">
                <div className="col-sm-4 col-md-3 flex-grow-1 item">
                  {ContactIcon.map((item, index) => {
                    return (
                      <div key={index} className="contactItem">
                        <div className="icon-wrapper">{item.icon}</div>
                        <a
                          href={item.link}
                          target={item.newTab ? "_blank" : "_self"}
                          className="link"
                          rel="noreferrer"
                        >
                          <div className="content">{item.content}</div>
                        </a>
                      </div>
                    );
                  })}
                </div>
                <div className="col col-lg-7">
                  <form
                    encType="application/x-www-form-urlencoded"
                    action="https://formsubmit.co/support@paperfreesolutions.com"
                    method="POST"
                  >
                    {/* hidden fields start */}
                    <input
                      type="hidden"
                      name="_next"
                      value="https://www.paperfreesolutions.com/#contact"
                    />
                    <input type="hidden" name="_captcha" value="false" />
                    <input
                      type="hidden"
                      name="_cc"
                      value="yashjoglekar1220@gmail.com"
                    />
                    {/* hidden fields ends */}

                    <h2
                      className="text-end"
                      style={{ color: "rgb(115, 111, 110)" }}
                    >
                      Write to us
                    </h2>
                    <div className="mb-3">
                      <div className={"row"}>
                        <div className={"col"}>
                          <input
                            required
                            className="form-control form-control-lg"
                            type="text"
                            name="name"
                            placeholder="Name"
                            onChange={(e) => {
                              this.setState({
                                formDetail: {
                                  ...this.state.formDetail,
                                  name: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <div className={"col"}>
                          <input
                            className="form-control form-control-lg"
                            type="tel"
                            name="phone"
                            placeholder="Phone"
                            onChange={(e) => {
                              this.setState({
                                formDetail: {
                                  ...this.state.formDetail,
                                  phone: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <input
                        required
                        className="form-control form-control-lg"
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={(e) => {
                          this.setState({
                            formDetail: {
                              ...this.state.formDetail,
                              email: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>

                    <div className="mb-3">
                      <textarea
                        className="form-control form-control-lg"
                        name="message"
                        placeholder="Message"
                        rows={8}
                        spellCheck="true"
                        onChange={(e) => {
                          this.setState({
                            formDetail: {
                              ...this.state.formDetail,
                              message: e.target.value,
                            },
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onMouseOver={this.handleMouseOverBtn}
                        onMouseOut={this.handleMouseOutBtn}
                        style={{
                          background: this.state.btnProperty.background,
                          borderColor: this.state.btnProperty.background,
                          paddingRight: "40px",
                          paddingLeft: "40px",
                          borderRadius: "20px",
                          fontFamily: "Maitree, serif",
                          width: this.state.btnProperty.width,
                          transition: "width 0.5s",
                        }}
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </footer>
        </div>

        <div
          className="container-fluid"
          style={{ background: "rgb(0, 148, 68)" }}
        >
          <div className="row">
            <div className="col">
              <h5
                className="text-center"
                style={{
                  color: "var(--bs-white)",
                  marginBottom: "18px",
                  marginTop: "20px",
                  fontFamily: "Maitree, serif",
                  fontWeight: "600",
                }}
              >
                <strong>© Copyrights - 2021. Paper Free Solutions</strong>
                <br />
              </h5>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
