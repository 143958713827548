import React from "react";
import logo from '../assets/logo/PFS_withText.png';

function NavBar() {
    return (
        <nav id={"myNav"} className="navbar navbar-light navbar-expand-md navigation-clean">
            <div className="container">
                <a className="navbar-brand" href={"#home"}>
                    <img className="img-fluid"
                         src={logo}
                         width="220px" height="220px"
                         alt={"logo"}/>
                </a>
                <button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navcol-1">
                    <span className="visually-hidden">
                        Toggle navigation
                    </span>
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="collapse navbar-collapse" id="navcol-1">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <a className="nav-link" href={"#home"}
                               style={{
                                   fontWeight: '700',
                                   fontFamily: 'Maitree, serif',
                                   fontSize:"18px"
                               }}>
                                Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href={"#whyUs"}
                               style={{
                                   fontWeight: '700',
                                   fontFamily: 'Maitree, serif',
                                   fontSize:"18px"
                               }}>
                                About us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href={"#ourProduct"}
                               style={{
                                   fontWeight: '700',
                                   fontFamily: 'Maitree, serif',
                                   fontSize:"18px"
                               }}>
                                Products</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href={"#contact"}
                               style={{
                                   fontWeight: '700',
                                   fontFamily: 'Maitree, serif',
                                   fontSize:"18px"
                               }}>Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;