import './App.css';
import React from "react";
import NavBar from "./pages/NavBar";
import Home from "./pages/Home";
import WhyUs from "./pages/WhyUs";
import Mission from "./pages/Mission";
import OurProcess from "./pages/OurProcess";
import OurProduct from "./pages/OurProduct";
import Contact from "./pages/Contact";

function App() {
  return (
    <div className="App">
      <NavBar/>
      <Home/>
      <WhyUs/>
      <Mission/>
      <OurProcess/>
      <OurProduct/>
      <Contact/>
    </div>
  );
}

export default App;
