import transparent from "../assets/images/TW_Transparent.png";
import mobile from "../assets/images/mobile_mock.png";
import React, {Component} from "react";
import Heading from "../components/Heading";

class Mission extends Component {

    constructor(props) {
        super(props);
        this.state = {
            property: {
                width: "50px",
                transition: "width 0.5s"
            }
        };
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    handleMouseOver() {
        this.setState({
            property: {
                width: "200px",
                transition: "width 0.5s"
            }
        });
    }

    handleMouseOut() {
        this.setState({
            property: {
                width: "50px",
                transition: "width 0.5s"
            }
        });
    }

    render() {
        return (
            <section id="mission">
                <section className="highlight-phone" style={{background: 'var(--bs-white)'}}>
                    <div className="container">
                        <div className="row d-xl-flex justify-content-xl-end">
                            <div className="col-md-8 col-lg-7">
                                <div className="intro" style={{textAlign: "left"}}>
                                    <Heading heading={"Mission"}/>
                                    <p data-aos="fade" data-aos-duration={600} style={{
                                        marginBottom: '24px',
                                        fontFamily: 'Maitree, serif',
                                        fontSize: '18px',
                                        fontWeight: "300", color: "black", lineHeight: "30px",textAlign: "justify"
                                    }}>
                                        <br/>Paper Free Team’s mission is providing trustworthy technology to every
                                        user.
                                        Our
                                        organization will assure you the best of services and security on your data. Our
                                        efforts
                                        to bring out intelligent ideas and build an effective and efficient application,
                                        best
                                        productivity, and business processes makes our applications get more of a
                                        personal
                                        touch. Our mission is to serve you by being efficient, productive, and value for
                                        money.
                                        We will maintain the timeless value of privacy and preserve the ability for you
                                        to
                                        control your data. Any global changes in the application will be at a very low
                                        cost.
                                        Small-scale businessmen and the self-employed entrepreneur will have an
                                        application
                                        that
                                        is effective, efficient, user-friendly, easy to understand and use. The Paper
                                        Free
                                        team
                                        will make sure you get efficient and user-friendly applications from us, with
                                        changes
                                        whenever required for the particular product. Every day, we work hard to earn
                                        your
                                        trust
                                        by focusing on privacy principles. We will provide you an application that has
                                        control
                                        over privacy with easy steps to make your daily work easier with choices. We are
                                        very
                                        transparent about your data policies; the data collected from you will be
                                        encrypted,
                                        and
                                        whenever an alteration is done to the application, we’ll keep you informed.<br/>
                                    </p>
                                </div>
                            </div>
                            <div
                                className="col-lg-3 col-sm-4 d-sm-flex flex-grow-1 align-self-center justify-content-sm-center justify-content-xl-start"
                            >
                                <img className="img-fluid"
                                     src={mobile}
                                     width={400} height={600} alt={""}/>
                            </div>
                        </div>
                    </div>
                    <div className="container" style={{textAlign: 'left'}}>
                        <Heading heading={"Team Work"}/>
                    </div>
                    <div className="container">
                        <div
                            className={"row d-sm-flex d-xxl-flex justify-content-sm-center justify-content-xxl-center"}>
                            <div className={"col col-lg-8"}>
                                <img className="img-fluid d-xxl-flex justify-content-xxl-center"
                                     data-aos="slide-right" data-aos-duration={550}
                                     src={transparent} alt={""}/>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        );
    };
}

export default Mission;