import React, {Component} from "react";

class Heading extends Component {

    constructor(props) {
        super(props);
        this.state = {
            property: {
                width: "50px",
                transition: "width 0.5s"
            }
        };
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    handleMouseOver() {
        this.setState({
            property: {
                width: "200px",
                transition: "width 0.5s"
            }
        });
    }

    handleMouseOut() {
        this.setState({
            property: {
                width: "50px",
                transition: "width 0.5s"
            }
        });
    }

    render() {
        return (
            <div>
                <h1 data-aos="fade-down"
                    data-aos-duration={600}
                    onMouseOver={this.handleMouseOver}
                    onMouseOut={this.handleMouseOut}
                    style={{
                        marginTop: '20px',
                        color: 'rgb(0, 148, 68)',
                        fontSize: '50px',
                        cursor: "pointer"
                    }}>
                    {this.props.heading}
                </h1>
                <div
                    style={{
                        width: this.state.property.width,
                        height: '20px',
                        background: 'var(--bs-yellow)',
                        borderRadius: '25px',
                        transition: this.state.property.transition
                    }}>
                </div>
            </div>
        );
    }
}

export default Heading;